.App {
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

.navbar {
  transition: transform 0.3s ease-in-out;
}

.navbar-hide {
  transform: translateY(-100%);
}
.navbar-shrink {
  padding: 0.5rem 1rem;
  background-color: rgba(31, 41, 55, 0.9); /* slightly transparent background */
}

@media (min-width: 768px) {
  .navbar-shrink {
    height: 50px;
  }
}

@media (max-width: 767px) {
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
  .navbar ul {
    display: flex;
    justify-content: space-around;
  }
}

html {
  scroll-behavior: smooth;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.carousel-root {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  
  
}